import React from 'react';
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { createUseStyles } from "react-jss";
import { SVG } from '@svgdotjs/svg.js';
import './preview_section.scss';
import imageDef from '../../../../img/shutterstock.png';
import deviceUiNight from '../../../../img/device-UI-night.svg';
import deviceUiDay from '../../../../img/device-UI-day.svg';
import PreviewNotifications from './PreviewNotifications';
import { getError } from '../../../../../redux/reticles/reticles-selectors';

const gridRender = require('../../../utils/gridRender');
const coords2GridFinder = require('../../../utils/coords2GridFinder');

const useStales = createUseStyles({
  previewCanvas: {
    width: 1280,
    height: 960,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundImage: (sceneUrl) => `url(${sceneUrl.device}), url(${sceneUrl.sceneUrl})`,
    borderRadius: 300,
  },
  previewDefault: {
    width: 1280,
    height: 960,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundImage: `url(${deviceUiDay}), url(${imageDef})`,
    borderRadius: 300,
  },
})

export default function Preview_section({ sceneUrl, activeTabId, timeOfDay, activeLink}) {
  let [draw, setDraw] = useState(null);
  const [svg, setSvg] = useState('');
  const error = useSelector(getError);
  const classes = useStales(timeOfDay === "NIGHT" ? { sceneUrl: sceneUrl, device: deviceUiNight } : { sceneUrl: sceneUrl, device: deviceUiDay });
  const devices = useSelector((state) => state.device);
  let [currentDevice, setCurrentDevice] = useState('X-Sight 5');
  let [currentWidth, setCurrentWidth] = useState(1280);
  let [currentHeight, setCurrentHeight] = useState(960);
  let [minX, setMinX] = useState(1280);
  let [minY, setMinY] = useState(960);
  let [maxX, setMaxX] = useState(0);
  let [maxY, setMaxY] = useState(0);
  let [scaleX, setScaleX] = useState(0);
  let [scaleY, setScaleY] = useState(0);
  let [currentCoeff, setCurrentCoeff] = useState(1);
  let previewGroup;

  const removeClasses = (svgData) => {
    const classArr = svgData.classes();

    classArr.forEach(className => {
      if (className !== 'preview-svg') {
        svgData.removeClass(className);
      }
    })
  }

  const renderCanvas = () => {
    const svgs = document.querySelectorAll('.preview-svg');

    svgs.forEach(el => el.remove());

    if (sessionStorage.getItem(activeTabId)) {
      if (draw) {
        draw.remove();
      }

      previewGroup = '';

      const previewSvg = JSON.parse(sessionStorage.getItem(activeTabId));

      if (previewSvg.svg) {
        setSvg(previewSvg.svg);

        draw = (SVG(previewSvg.svg.replace(/class="canvas-block"/gi, 'class="preview-svg"')).addTo(`#preview_svg-${activeTabId}`).addClass('preview-svg').size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`));
        
        removeClasses(draw);

        draw.each(function(item, arr) {
          if (arr[item].type === 'ellipse') {
            arr[item].remove()
          }
        })

        previewGroup = draw.group();
      }
    } else {
      if (draw) {
        draw.remove();
        previewGroup = '';
      }

      draw = (SVG().addTo(`#preview_svg-${activeTabId}`).addClass('preview-svg').size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`));

      draw.each(function(item, arr) {
        if (arr[item].type === 'ellipse') {
          arr[item].remove()
        }
      })

      previewGroup = draw.group();
    };
  }

  const findtMinMaxPoints = (shape) => {
    const shapeStrokeW = shape.attr('stroke-width');

    if (shape.type === 'line') {
      let lineArr = shape.array();

      if (lineArr[0][0] < minX) {
        setMinX(lineArr[0][0]);
      }
  
      if (lineArr[0][1] < minY) {
        setMinY(lineArr[0][1]);
      }
  
      if (lineArr[1][0] > maxX) {
        setMaxX(lineArr[1][0]);
      }
  
      if (lineArr[1][1] > maxY) {
        setMaxY(lineArr[1][1]);
      }
    } else if (shape.type === 'circle') {
      if (shape.cx() - (shape.radius() / 2) - shapeStrokeW < minX) {
        setMinX(shape.cx() - (shape.radius() / 2) - shapeStrokeW);
      }
  
      if (shape.cy() - (shape.radius() / 2) - shapeStrokeW < minY) {
        setMinY(shape.cy() - (shape.radius() / 2) - shapeStrokeW);
      }
  
      if (shape.cx() + (shape.radius() / 2) + shapeStrokeW > maxX) {
        setMaxX(shape.cx() + (shape.radius() / 2) + shapeStrokeW);
      }
  
      if (shape.cy() + (shape.radius() / 2) + shapeStrokeW > maxY) {
        setMaxY(shape.cy() + (shape.radius() / 2) + shapeStrokeW);
      }
    } else {
      if (shape.x() < minX) {
        setMinX(shape.x());
      }
  
      if (shape.y() < minY) {
        setMinY(shape.y());
      }
  
      if (shape.x() + shape.width() > maxX) {
        setMaxX(shape.x() + shape.width());
      }
  
      if (shape.y() + shape.height() > maxY) {
        setMaxY(shape.y() + shape.height());
      }
    }
  }

  useEffect(() => {
    console.log('minX ', minX);
    console.log('minY ', minY);
  }, [minX, minY])

  const findNewCenter = (w, h) => {
    const cx = (((maxX + minX) / 2) / 600) * w;
    const cy = (((maxY + minY) / 2) / 450) * h;

    return [cx, cy];
  }

  const editShape2device = (shape, coeff, w, h, previewG) => {
    let scaleX = w / 600;
    let scaleY = h / 450;
    let minScale = Math.min(scaleX, scaleY);

    switch(shape.type) {
      case 'circle':
        let newCx = shape.cx() * scaleX;
        let newCy = shape.cy() * scaleY;
        let newR = shape.radius() * minScale;

        shape.center(newCx, newCy);
        shape.radius(newR);
        // shape.scale(coeff);

        break;
      case 'line':
        let lineCoords = shape.array();

        let newLineCoords = lineCoords.map(el => {
          let x = el[0] * scaleX;
          let y = el[1] * scaleY;

          return [x, y];
        });

        shape.plot(newLineCoords);
        // shape.scale(coeff);

        break;
      case 'rect':
        let rectX = shape.x() * scaleX;
        let rectY = shape.y() * scaleY;

        shape.x(rectX);
        shape.y(rectY);
        // shape.scale(coeff);

        break;
      case 'polygon':
        let polyX = shape.cx() * scaleX;
        let polyY = shape.cy() * scaleY;
        let polyW = shape.width() * scaleX;
        let polyH = shape.height() * scaleY;

        shape.width(polyW);
        shape.height(polyH);
        shape.cx(polyX);
        shape.cy(polyY);

        break;
      case 'g':
        if (shape.hasClass('number-group')) {
          let polyXg = shape.cx() * scaleX;
          let polyYg = shape.cy() * scaleY;
          let polyWg = shape.width() * scaleX;
          let polyHg = shape.height() * scaleY;
  
          shape.width(polyWg);
          shape.height(polyHg);
          shape.cx(polyXg);
          shape.cy(polyYg);
        }
        break;
      default:
    }

    if (!shape.id().includes('Svg')) {
      previewG.add(shape);
    }

    draw.zoom(coeff);

    // previewG.transform({
      // translateX: newX,
      // translateY: newY,
      // scale: coeff
    // })
  }

  const setCurrentDeviceData = () => {
    let [currentData] = devices.deviceData.filter(el => el.id === activeTabId);

    try {
      if (currentData && currentData.device !== currentDevice) {
        setCurrentDevice(devices.currentDevice);
      }
  
      if (currentData && currentData.device === 'X-Sight 5') {
        setCurrentWidth(1280);
        setCurrentHeight(960);
      } else if (currentData && currentData.device === 'Thor') {
        setCurrentWidth(1024);
        setCurrentHeight(768);
      }
    } catch(e) {
      console.log(e)
    }
  }

  const centerGroup = (w, h) => {
    // let centerPoints = findNewCenter(w, h);
    const buffSvg = JSON.parse(sessionStorage.getItem(activeTabId));
    const svgImage = SVG(buffSvg.svg);
    const imageChilds = svgImage.children();

    // let scaleX = 1280 / 7300;
    // let scaleY = 960 / 5500;
    // let minScale = Math.min(scaleX, scaleY);

    const buffG = SVG().group();
    
    imageChilds.forEach(el => {
      buffG.add(el);
    })

    let newCX = buffG.cx() / 600 * w;
    let newCY = buffG.cy() / 450 * h;

    try {
      if (previewGroup) {
        // previewGroup.x(minX * minScale);
        // previewGroup.y(minY * minScale);
        // previewGroup.center(newCX, newCY);
      }
    } catch(e) {
      console.log(e);
    }

    buffG.remove();
  }

  const calculateShapeDimensions = () => {
    let [currentData] = devices.deviceData.filter(el => el.id === activeTabId);

    try {
      if (draw) {
        if (currentData && currentData.device === 'X-Sight 5') {
          if (currentData && currentData.lens === '5-25X') {
            draw.each((i, elementsArr) => {
              // editShape2device(elementsArr[i], 1, 1280, 960, previewGroup);
              setCurrentCoeff(4.2);
              findtMinMaxPoints(elementsArr[i]);
              editShape2device(elementsArr[i], 4.2, 1280, 960, previewGroup);
            })
            // previewGroup.translate(320, 240)
            // previewGroup.scale(4.2)
          } else if (currentData && currentData.lens === '3-15X') {
            draw.each((i, elementsArr) => {
              // editShape2device(elementsArr[i], 1, 1280, 960, previewGroup);
              setCurrentCoeff(2.77);
              findtMinMaxPoints(elementsArr[i]);
              editShape2device(elementsArr[i], 2.77, 1280, 960, previewGroup);
            })
            // previewGroup.translate(320, 240)
            // previewGroup.scale(2.77)
          }
          
          // centerGroup(1280, 960);
          // previewGroup.center(centerPoints[0], centerPoints[1]);
          // draw = (SVG().addTo(`#preview_svg-${activeTabId}`).addClass('preview-svg').size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`));
          // draw.size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`);
        }

        if (currentData && currentData.device === 'Thor') {
          if (currentData && currentData.sensor === '320') {
            if (currentData && currentData.lens === '3-12X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 1.717, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '4-16X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 2.17, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '5-20X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 3.13, 1024, 768, previewGroup);
              })
            }

            // centerGroup(1024, 765);
            // let centerPoints = findNewCenter(1024, 765);

            // previewGroup.center(centerPoints[0], centerPoints[1])
          }

          if (currentData && currentData.sensor === '640') {
            if (currentData && currentData.lens === '3-12X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 1.59, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '4-16X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 2.25, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '5-20X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 3.33, 1024, 768, previewGroup);
              })
            }

            // centerGroup(1024, 765);
            // let centerPoints = findNewCenter(1024, 765);

            // previewGroup.center(centerPoints[0], centerPoints[1])
          }

          if (currentData && currentData.sensor === '1280') {
            if (currentData && currentData.lens === '3-12X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 1.37, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '4-16X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 2.1, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '5-20X') {
              draw.each((i, elementsArr) => {
                findtMinMaxPoints(elementsArr[i]);
                editShape2device(elementsArr[i], 2.73, 1024, 768, previewGroup);
              })
            }

            // centerGroup(1024, 765);
            // let centerPoints = findNewCenter(1024, 765);

            // previewGroup.center(centerPoints[0], centerPoints[1])
          }

          // draw.size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`);
        }
      }
    } catch(e) {
      console.log('Error!', e)
    }
  }

  useEffect(() => {
    // setMinX(1280);
    // setMinY(960);
    // setMaxX(0);
    // setMaxY(0);

    minX = 1280;
    minY = 960;
    maxX = 0;
    maxY = 0;
    
    renderCanvas();
    setCurrentDeviceData();
    calculateShapeDimensions();
  }, [activeTabId]);

  useEffect(() => {
    renderCanvas();
    setCurrentDeviceData();
    calculateShapeDimensions();

    // centerGroup(currentWidth, currentHeight);
  }, [currentWidth, currentHeight, previewGroup, minX])

  useEffect(() => {
    renderCanvas();
    setCurrentDeviceData();
    calculateShapeDimensions();

    sessionStorage.setItem('devices', JSON.stringify(devices.deviceData));
  }, [devices])

  useEffect(() => {
    renderCanvas();
    calculateShapeDimensions();
  }, [currentDevice])

  useEffect(() => {
    renderCanvas();
    setCurrentDeviceData();
    calculateShapeDimensions();
    setScaleX(1280/600);
    setScaleY(960/450);
  }, [])

  return (
    <section className='preview_section'>
      <section
        id='preview_canvas'
        className={sceneUrl === '' ? classes.previewDefault : classes.previewCanvas}
        style={{width: `${currentWidth}px`, height: `${currentHeight}px`, borderRadius: currentDevice === 'Thor' ? '250px' : '300px'}}
      >
        <div 
          id={`preview_svg-${activeTabId}`}
          className='preview_canvas-block'
          style={{width: `${currentWidth}px`, height: `${currentHeight}px`}}
        ></div>
      </section>
      {error? (
        <PreviewNotifications activeLink={activeLink}/>
      ) : null}
      {/* <section className='preview_section-zoom'>
        <button className='preview_section-zoom-btn zoom-in_btn'></button>
        <input type='range' min='0' max='100' className='preview_zoom-input' />
        <button className='preview_section-zoom-btn zoom-out_btn'></button>
      </section> */}
    </section>
  );
}
