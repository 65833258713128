import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBackgrounds } from '../../../../../redux/backgrounds/backgrounds-operations';
import { getBackgrounds } from '../../../../../redux/backgrounds/backgrounds-selectors';
import { clearBackgrounds } from '../../../../../redux/backgrounds/backgrounds-actions';
import { getIsAuthenticated } from '../../../../../redux/auth/auth-selectors';

import SceneCard from './sceneCard';

import './scene.scss';

const Scene = ({ openScene }) => {
    const dispatch = useDispatch();
    const previewImages = useSelector(getBackgrounds);
    const [open, setOpen] = useState('DAY');
    const isUserAuthenticated = useSelector(getIsAuthenticated);

    useEffect(() => {
        if (isUserAuthenticated) {
            dispatch(fetchBackgrounds('timeOfDay=DAY'));
        }
    }, [isUserAuthenticated])


    const handleOpen = (value) => {
        setOpen(value);
        if (value === '') {
            return
        } else if (value === 'DAY') {
            dispatch(clearBackgrounds());
            dispatch(fetchBackgrounds('timeOfDay=DAY'));
        } else if (value === 'NIGHT') {
            dispatch(clearBackgrounds());
            dispatch(fetchBackgrounds('timeOfDay=NIGHT&typeOfDevice=NORMAL'));
        } else if (value === 'THERMAL') {
            dispatch(clearBackgrounds());
            dispatch(fetchBackgrounds('typeOfDevice=THERMAL'));
        }
    };

    const SceneCardBlock = () => {
        // console.log('previewImages', previewImages)
        return (
            <ul style={{ padding: '0px', margin: '0px' }}>
                {previewImages.length ? (
                    previewImages.map(previewImage => (
                        <SceneCard key={previewImage.id} previewImage={previewImage} openScene={openScene} />
                    ))
                ) : null}
            </ul>
        );
    };

    return (
        <section className='scene_list'>
            <section className='scene_list-title-section'>
                <h2 className='scene_list-title'>Scenes</h2>
                <div className='scene_button-block'>
                    <button className={`scene_button ${open === 'DAY' ? 'buttonTop' : 'buttonBottom'}`} onClick={() => open === 'DAY' ? handleOpen('') : handleOpen('DAY')}>Day</button>
                    {open === 'DAY' ?
                        <div className='scene-block'>
                            {SceneCardBlock()}
                        </div>
                        : null}

                    <button className={`scene_button ${open === 'NIGHT' ? 'buttonTop' : 'buttonBottom'}`} onClick={() => open === 'NIGHT' ? handleOpen('') : handleOpen('NIGHT')} >Night</button>
                    {open === 'NIGHT' ?
                        <div className='scene-block'>
                            {SceneCardBlock()}
                        </div>
                        : null}

                    <button className={`scene_button ${open === 'THERMAL' ? 'buttonTop' : 'buttonBottom'}`} onClick={() => open === 'THERMAL' ? handleOpen('') : handleOpen('THERMAL')} >Thermal</button>
                    {open === 'THERMAL' ?
                        <div className='scene-block'>
                            {SceneCardBlock()}
                        </div>
                        : null}
                </div>
            </section>
        </section>
    );
}
export default Scene
